import React from 'react'
import AssignmentIcon from '@material-ui/icons/Assignment'
import {
  Edit,
  SimpleForm,
  ReferenceField,
  TextField,
  FunctionField,
  DateInput,
  required
} from 'react-admin'

export const CompanyRequirementIcon = AssignmentIcon

const validateRequired = required()

export const CompanyRequirementEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceField source='requirement.id' reference='Requirement'>
        <FunctionField render={record => `${record.title} > ${record.headline}`} />
      </ReferenceField>
      <DateInput source='compliedAt' locales='de-DE' validate={validateRequired} />
      <ReferenceField source='company.id' reference='Company'>
        <TextField source='name' />
      </ReferenceField>
    </SimpleForm>
  </Edit>
)
