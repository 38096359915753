import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import PropTypes from 'prop-types'

const CalcSum = ({ record }) => {
  const dataProvider = useDataProvider()
  const [commission, setCommision] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  useEffect(() => {
    dataProvider.getOne('Provider', { id: record.provider.id }).then(({ data }) => {
      setCommision(data.commission)
      setLoading(false)
    }).catch(error => {
      setError(error)
      setLoading(false)
    })
  })

  if (loading) return null
  if (error) return null
  if (!commission) return null

  return (
    <span>{(((record.price - record.includedAdditionalCosts) * (commission / 100)) / 100).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</span>
  )
}

CalcSum.propTypes = {
  record: PropTypes.shape({
    price: PropTypes.number.isRequired,
    includedAdditionalCosts: PropTypes.number.isRequired,
    provider: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired
}

export default CalcSum
