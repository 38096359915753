import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Edit,
  TabbedForm,
  FormTab,
  TextField,
  ReferenceField,
  NullableBooleanInput,
  SelectInput
} from 'react-admin'

const useStyles = makeStyles(
  theme => ({
    input: { width: '100%' }
  }),
  { name: 'RaNullableBooleanInput' }
)

export const employeeCountTypes = [
  { id: 'LESS_THAN_20', name: '< 20' },
  { id: 'BETWEEN_20_AND_99', name: '>= 20' },
  { id: 'BETWEEN_100_AND_250', name: '>= 100' },
  { id: 'BETWEEN_251_AND_1499', name: '> 250' },
  { id: 'GREATER_OR_EQUAL_1500', name: '>= 1500' }
]

export const RequirementCriterionEdit = props => {
  const classes = useStyles()
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label='resources.RequirementCriterion.tabs.general'>
          <SelectInput source='employeeCount' choices={employeeCountTypes} allowEmpty />
          <NullableBooleanInput source='dangerousSubstances' className={classes.input} />
          <NullableBooleanInput source='dangerousGoods' className={classes.input} />
          <NullableBooleanInput source='industrialTrucks' className={classes.input} />
          <NullableBooleanInput source='workAtHeight' className={classes.input} />
          <NullableBooleanInput source='cranes' className={classes.input} />
          <NullableBooleanInput source='poweredDoors' className={classes.input} />
          <NullableBooleanInput source='passengerElevators' className={classes.input} />
          <NullableBooleanInput source='freightElevators' className={classes.input} />
          <NullableBooleanInput source='storageEquipment' className={classes.input} />
          <NullableBooleanInput source='ladders' className={classes.input} />
          <NullableBooleanInput source='seriouslyDisabledPersons' className={classes.input} />
          <NullableBooleanInput source='adolescents' className={classes.input} />
          <NullableBooleanInput source='healthManagement' className={classes.input} />
          <NullableBooleanInput source='qualityManagementSystem' className={classes.input} />
          <NullableBooleanInput source='environmentalManagementSystem' className={classes.input} />
          <NullableBooleanInput source='energyManagementSystem' className={classes.input} />
          <NullableBooleanInput source='healthAndSafetyManagementSystem' className={classes.input} />
        </FormTab>

        <FormTab label='resources.RequirementCriterion.tabs.company'>

          <ReferenceField source='company.id' reference='Company' addLabel={false}>
            <TextField source='name' />
          </ReferenceField>
        </FormTab>

        <FormTab label='resources.RequirementCriterion.tabs.requirement'>
          <ReferenceField source='requirement.id' reference='Requirement' addLabel={false}>
            <TextField source='title' />
          </ReferenceField>
        </FormTab>

      </TabbedForm>
    </Edit>
  )
}
