import React, { useCallback } from 'react'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { useForm } from 'react-final-form'
import {
  Create,
  Edit,
  List,
  Pagination,
  Datagrid,
  DateField,
  TextField,
  TextInput,
  FileInput,
  FileField,
  SimpleForm,
  SaveButton,
  Toolbar,
  required
} from 'react-admin'

import S3FileField from '../fields/S3FileField'

export const FileIcon = AttachFileIcon

const validateRequired = required()

const FileTitle = ({ record }) => {
  return <span>{record.name}</span>
}

const SaveWithFileButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm()

  const handleClick = useCallback(async () => {
    const { file } = form.getState().values
    const request = (await fetch(process.env.REACT_APP_FILE_UPLOAD_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ filename: file.rawFile.path })
    }))
    const { url, key, extension } = await request.json()

    // upload pdf to aws
    const res = await fetch(url, {
      method: 'PUT',
      body: file.rawFile,
      headers: { 'Content-Type': `${file.rawFile.type}; charset="utf-8"` }
    })
    if (res.ok) {
      form.change('file', undefined)
      form.change('contentType', file.rawFile.type)
      form.change('key', key)
      form.change('extension', extension)

      handleSubmitWithRedirect('edit')
    }
  }, [form, handleSubmitWithRedirect])
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
}

const FileCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveWithFileButton />
  </Toolbar>
)

export const FileList = props => (
  <List
    {...props}
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    sort={{ field: 'createdAt', order: 'DESC' }}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <DateField source='createdAt' locales='de-DE' showTime />
    </Datagrid>
  </List>
)

export const FileCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<FileCreateToolbar />}>
      <FileInput source='file'>
        <FileField source='src' title='title' />
      </FileInput>
      <TextInput source='name' validate={validateRequired} />
    </SimpleForm>
  </Create>
)

export const FileEdit = props => (
  <Edit {...props} title={<FileTitle />}>
    <SimpleForm>
      <S3FileField source='key' />
      <TextInput source='name' validate={validateRequired} />
    </SimpleForm>
  </Edit>
)
