import React from 'react'
import GavelIcon from '@material-ui/icons/Gavel'
import {
  Create,
  Edit,
  List,
  Pagination,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput
} from 'react-admin'

export const RegulationIcon = GavelIcon

const RegulationTitle = ({ record }) => {
  return <span>{record.name} {record.abbreviation && `(${record.abbreviation})`}</span>
}

export const RegulationList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <TextField source='abbreviation' />
    </Datagrid>
  </List>
)

export const RegulationEdit = props => (
  <Edit
    {...props}
    title={<RegulationTitle />}
  >
    <SimpleForm>
      <TextInput source='name' />
      <TextInput source='abbreviation' />
    </SimpleForm>
  </Edit>
)

export const RegulationCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source='name' />
      <TextInput source='abbreviation' />
    </SimpleForm>
  </Create>
)
