import React from 'react'
import ReactDOM from 'react-dom'
import { Admin, Resource } from 'react-admin'

import getDataProvider from './dataProvider'
import authProvider from './authProvider'
import i18nProvider from './i18nProvider'

// import theme from './theme'

// import Dashboard from './components/Dashboard'

import { UserIcon, UserList, UserEdit } from './components/resources/User'
import { CompanyIcon, CompanyList, CompanyEdit } from './components/resources/Company'
import { CompanyRequirementIcon, CompanyRequirementEdit } from './components/resources/CompanyRequirement'
import { RequirementCriterionEdit } from './components/resources/RequirementCriterion'
import { RequirementIcon, RequirementList, RequirementCreate, RequirementEdit } from './components/resources/Requirement'
import { AddressIcon, AddressCreate, AddressList, AddressEdit } from './components/resources/Address'
import { ProviderIcon, ProviderCreate, ProviderList, ProviderEdit } from './components/resources/Provider'
import { TrainingIcon, TrainingCreate, TrainingList, TrainingEdit } from './components/resources/Training'
import { TrainingEventIcon, TrainingEventCreate, TrainingEventEdit } from './components/resources/TrainingEvent'
import { TrainingBookingIcon, TrainingBookingList, TrainingBookingEdit } from './components/resources/TrainingBooking'
import { ServiceQuotationRequestIcon, ServiceQuotationRequestList, ServiceQuotationRequestEdit } from './components/resources/ServiceQuotationRequest'
import { ServiceQuotationIcon, ServiceQuotationList, ServiceQuotationEdit, ServiceQuotationCreate } from './components/resources/ServiceQuotation'
import { RegulationIcon, RegulationCreate, RegulationList, RegulationEdit } from './components/resources/Regulation'
import { ServiceTagIcon, ServiceTagCreate, ServiceTagList, ServiceTagEdit } from './components/resources/ServiceTag'
import { RatingIcon, RatingEdit } from './components/resources/Rating'
import { FileIcon, FileList, FileEdit, FileCreate } from './components/resources/File'
import { ServiceTagFaqIcon, ServiceTagFaqList, ServiceTagFaqEdit, ServiceTagFaqCreate } from './components/resources/ServiceTagFaq'

class App extends React.Component {
  constructor () {
    super()
    this.state = { dataProvider: null }
  }

  componentDidMount () {
    getDataProvider(process.env.REACT_APP_PRISMA_URL)
      .then(dataProvider => this.setState({ dataProvider }))
  }

  render () {
    const { dataProvider } = this.state
    if (!dataProvider) return null

    return (
      <Admin
        title='OPTICERT Admin'
        // theme={theme}
        // dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
      >
        <Resource
          name='User'
          icon={UserIcon}
          list={UserList}
          edit={UserEdit}
        />
        <Resource
          name='Company'
          icon={CompanyIcon}
          list={CompanyList}
          edit={CompanyEdit}
        />
        <Resource
          name='CompanyRequirement'
          icon={CompanyRequirementIcon}
          // list={CompanyRequirementList}
          edit={CompanyRequirementEdit}
        />
        <Resource
          name='RequirementCriterion'
          edit={RequirementCriterionEdit}
        />
        <Resource
          name='Requirement'
          icon={RequirementIcon}
          list={RequirementList}
          edit={RequirementEdit}
          create={RequirementCreate}
        />
        <Resource
          name='Address'
          icon={AddressIcon}
          list={AddressList}
          edit={AddressEdit}
          create={AddressCreate}
        />
        <Resource
          name='Provider'
          icon={ProviderIcon}
          list={ProviderList}
          edit={ProviderEdit}
          create={ProviderCreate}
        />
        <Resource
          name='ServiceTag'
          icon={ServiceTagIcon}
          list={ServiceTagList}
          edit={ServiceTagEdit}
          create={ServiceTagCreate}
        />
        <Resource
          name='Training'
          icon={TrainingIcon}
          list={TrainingList}
          edit={TrainingEdit}
          create={TrainingCreate}
        />
        <Resource
          name='TrainingEvent'
          icon={TrainingEventIcon}
          // list={TrainingEventList}
          edit={TrainingEventEdit}
          create={TrainingEventCreate}
        />
        <Resource
          name='TrainingBooking'
          icon={TrainingBookingIcon}
          list={TrainingBookingList}
          edit={TrainingBookingEdit}
        />
        <Resource
          name='ServiceQuotationRequest'
          icon={ServiceQuotationRequestIcon}
          list={ServiceQuotationRequestList}
          edit={ServiceQuotationRequestEdit}
        />
        <Resource
          name='ServiceQuotation'
          icon={ServiceQuotationIcon}
          list={ServiceQuotationList}
          edit={ServiceQuotationEdit}
          create={ServiceQuotationCreate}
        />
        <Resource
          name='Regulation'
          icon={RegulationIcon}
          list={RegulationList}
          edit={RegulationEdit}
          create={RegulationCreate}
        />
        <Resource
          name='Rating'
          icon={RatingIcon}
          // list={RatingList}
          edit={RatingEdit}
        />
        <Resource
          name='File'
          icon={FileIcon}
          list={FileList}
          edit={FileEdit}
          create={FileCreate}
        />
        <Resource
          name='ServiceTagFaq'
          icon={ServiceTagFaqIcon}
          list={ServiceTagFaqList}
          edit={ServiceTagFaqEdit}
          create={ServiceTagFaqCreate}
        />
      </Admin>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
