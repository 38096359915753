import React from 'react'
import ReceiptIcon from '@material-ui/icons/Receipt'
import {
  Edit,
  List,
  Pagination,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
  FunctionField,
  DateField,
  DateInput,
  SimpleForm
} from 'react-admin'

export const TrainingBookingIcon = ReceiptIcon

const TrainingBookingTitle = ({ record }) => <span>Schulungsbuchung {new Date(record.createdAt).toLocaleString('de-DE')}</span>

export const TrainingBookingList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <DateField source='createdAt' locales='de-DE' showTime />
      <ReferenceField source='event.id' reference='TrainingEvent'>
        <ReferenceField source='training.id' reference='Training'>
          <ReferenceField source='serviceTag.id' reference='ServiceTag' link={(record, reference) => `/${reference}/${record.serviceTag.id}`}>
            <TextField source='name' />
          </ReferenceField>
        </ReferenceField>
      </ReferenceField>
      <NumberField source='participantCount' />
      <FunctionField label='resources.TrainingBooking.fields.totalPrice' render={record => `${record.pricePerParticipant * record.participantCount / 100} €`} />
      <ReferenceField source='user.id' reference='User'>
        <FunctionField render={record => `${record.forename} ${record.surname}`} />
      </ReferenceField>
    </Datagrid>
  </List>
)

export const TrainingBookingEdit = props => {
  return (
    <Edit {...props} title={<TrainingBookingTitle />}>
      <SimpleForm>
        <DateField source='createdAt' locales='de-DE' showTime />
        <NumberField source='participantCount' />
        <FunctionField label='resources.TrainingBooking.fields.totalPrice' render={record => `${record.pricePerParticipant * record.participantCount / 100} €`} />
        <ReferenceField source='user.id' reference='User'>
          <FunctionField render={record => `${record.forename} ${record.surname}`} />
        </ReferenceField>
        <ReferenceField source='event.id' reference='TrainingEvent'>
          <DateField source='startDate' locales='de-DE' />
        </ReferenceField>
        <DateInput source='invoicedAt' locales='de-DE' />
      </SimpleForm>
    </Edit>
  )
}
