import React from 'react'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import { makeStyles } from '@material-ui/core/styles'
import RichTextInput from 'ra-input-rich-text'
import {
  Create,
  Edit,
  List,
  Pagination,
  Datagrid,
  TabbedForm,
  FormTab,
  BooleanField,
  FunctionField,
  SelectField,
  DateField,
  TextField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  TextInput,
  NullableBooleanInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useTranslate,
  required
} from 'react-admin'

import { employeeCountTypes } from './RequirementCriterion'
import { topicChoices, occurrenceProbabilityChoices, indexChoices } from '../utils'

export const RequirementIcon = MenuBookIcon

const validateRequired = required()

const RequirementTitle = ({ record }) => {
  return <span>{record.title}</span>
}

const useStyles = makeStyles(
  theme => ({
    input: { width: '100%' }
  }),
  { name: 'RaNullableBooleanInput' }
)

export const RequirementList = props => {
  const translate = useTranslate()
  return (
    <List
      {...props}
      perPage={1000}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    >
      <Datagrid rowClick='edit'>
        <SelectField source='topic' choices={topicChoices} />
        <TextField source='title' />
        <FunctionField label='resources.Requirement.fields.timeInterval' render={record => `${record.timeInterval} ${translate('words.days')}`} />
        <BooleanField source='isIntervalMandatory' />
        <NumberField source='fine' locales='de-DE' options={{ style: 'currency', currency: 'EUR' }} />
        <FunctionField label='resources.Requirement.fields.companyRequirements' render={record => record.companyRequirements.length} />
      </Datagrid>
    </List>
  )
}

export const RequirementEdit = props => (
  <Edit
    {...props}
    title={<RequirementTitle />}
  >
    <TabbedForm>
      <FormTab label='resources.Requirement.tabs.general'>
        <SelectInput source='topic' choices={topicChoices} validate={[required()]} />
        <TextInput source='title' validate={validateRequired} fullWidth />
        <TextInput source='headline' validate={validateRequired} fullWidth />
        <RichTextInput source='text' toolbar={[['bold', { list: 'bullet' }, 'link']]} validate={validateRequired} />
        <NumberInput source='timeInterval' validate={validateRequired} />
        <BooleanInput source='isIntervalMandatory' />
        <NumberInput source='fine' />
      </FormTab>

      <FormTab label='resources.Requirement.tabs.assessment'>
        <SelectInput source='riskProbabilityIndex' choices={occurrenceProbabilityChoices} validate={validateRequired} />
        <SelectInput source='damageIndex' choices={indexChoices} validate={validateRequired} />
        <SelectInput source='effortIndex' choices={indexChoices} validate={validateRequired} />
      </FormTab>

      <FormTab label='resources.Requirement.tabs.criterion'>
        <ReferenceField source='criterion.id' reference='RequirementCriterion' addLabel={false}>
          <FunctionField render={record => 'Kriterien'} />
        </ReferenceField>
      </FormTab>

      <FormTab label='resources.Requirement.tabs.serviceTags'>
        <ReferenceArrayInput source='serviceTagsIds' reference='ServiceTag' perPage={1000} addLabel={false} fullWidth>
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
      </FormTab>

      <FormTab label='resources.Requirement.tabs.files'>
        <ReferenceArrayInput source='filesIds' reference='File' perPage={1000} addLabel={false} fullWidth>
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
      </FormTab>

      <FormTab label='resources.Requirement.tabs.companyRequirements'>
        <ReferenceManyField target='requirement.id' reference='CompanyRequirement' addLabel={false} fullWidth>
          <Datagrid>
            <ReferenceField source='company.id' reference='Company'>
              <TextField source='name' />
            </ReferenceField>
            <DateField source='compliedAt' locales='de-DE' />
            <DateField source='createdAt' locales='de-DE' />
          </Datagrid>
        </ReferenceManyField>

      </FormTab>

    </TabbedForm>
  </Edit>
)

export const RequirementCreate = props => {
  const classes = useStyles()
  return (
    <Create {...props}>
      <TabbedForm>
        <FormTab label='resources.Requirement.tabs.general'>
          <SelectInput source='topic' choices={topicChoices} validate={[required()]} />
          <TextInput source='title' validate={validateRequired} fullWidth />
          <TextInput source='headline' validate={validateRequired} fullWidth />
          <RichTextInput source='text' toolbar={[['bold', { list: 'bullet' }]]} validate={validateRequired} />
          <NumberInput label='Prüfungsintervall (in Tagen)' source='timeInterval' validate={validateRequired} />
          <BooleanInput source='isIntervalMandatory' />
          <NumberInput source='fine' />
        </FormTab>

        <FormTab label='resources.Requirement.tabs.assessment'>
          <SelectInput source='riskProbabilityIndex' choices={occurrenceProbabilityChoices} validate={validateRequired} />
          <SelectInput source='damageIndex' choices={indexChoices} validate={validateRequired} />
          <SelectInput source='effortIndex' choices={indexChoices} validate={validateRequired} />
        </FormTab>

        <FormTab label='resources.Requirement.tabs.criterion'>
          <SelectInput source='criterion.employeeCount' label='resources.RequirementCriterion.fields.employeeCount' choices={employeeCountTypes} allowEmpty />
          <NullableBooleanInput source='criterion.dangerousSubstances' label='resources.RequirementCriterion.fields.dangerousSubstances' className={classes.input} />
          <NullableBooleanInput source='criterion.dangerousGoods' label='resources.RequirementCriterion.fields.dangerousGoods' className={classes.input} />
          <NullableBooleanInput source='criterion.industrialTrucks' label='resources.RequirementCriterion.fields.industrialTrucks' className={classes.input} />
          <NullableBooleanInput source='criterion.workAtHeight' label='resources.RequirementCriterion.fields.workAtHeight' className={classes.input} />
          <NullableBooleanInput source='criterion.cranes' label='resources.RequirementCriterion.fields.cranes' className={classes.input} />
          <NullableBooleanInput source='criterion.poweredDoors' label='resources.RequirementCriterion.fields.poweredDoors' className={classes.input} />
          <NullableBooleanInput source='criterion.passengerElevators' label='resources.RequirementCriterion.fields.passengerElevators' className={classes.input} />
          <NullableBooleanInput source='criterion.freightElevators' label='resources.RequirementCriterion.fields.freightElevators' className={classes.input} />
          <NullableBooleanInput source='criterion.storageEquipment' label='resources.RequirementCriterion.fields.storageEquipment' className={classes.input} />
          <NullableBooleanInput source='criterion.ladders' label='resources.RequirementCriterion.fields.ladders' className={classes.input} />
          <NullableBooleanInput source='criterion.seriouslyDisabledPersons' label='resources.RequirementCriterion.fields.seriouslyDisabledPersons' className={classes.input} />
          <NullableBooleanInput source='criterion.adolescents' label='resources.RequirementCriterion.fields.adolescents' className={classes.input} />
          <NullableBooleanInput source='criterion.healthManagement' label='resources.RequirementCriterion.fields.healthManagement' className={classes.input} />
          <NullableBooleanInput source='criterion.qualityManagementSystem' label='resources.RequirementCriterion.fields.qualityManagementSystem' className={classes.input} />
          <NullableBooleanInput source='criterion.environmentalManagementSystem' label='resources.RequirementCriterion.fields.environmentalManagementSystem' className={classes.input} />
          <NullableBooleanInput source='criterion.energyManagementSystem' label='resources.RequirementCriterion.fields.energyManagementSystem' className={classes.input} />
          <NullableBooleanInput source='criterion.healthAndSafetyManagementSystem' label='resources.RequirementCriterion.fields.healthAndSafetyManagementSystem' className={classes.input} />
        </FormTab>

        <FormTab label='resources.Requirement.tabs.serviceTags'>
          <ReferenceArrayInput source='serviceTagsIds' reference='ServiceTag' perPage={1000} addLabel={false} fullWidth>
            <AutocompleteArrayInput />
          </ReferenceArrayInput>
        </FormTab>

      </TabbedForm>
    </Create>
  )
}
