import React from 'react'
import { useForm } from 'react-final-form'
import RichTextInput from 'ra-input-rich-text'
import BuildIcon from '@material-ui/icons/Build'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import {
  Button,
  Create,
  Edit,
  List,
  Pagination,
  Link,
  Datagrid,
  TextField,
  ReferenceManyField,
  ReferenceField,
  FunctionField,
  DateField,
  SelectField,
  SimpleForm,
  TabbedForm,
  FormTab,
  TextInput,
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceArrayField,
  EditButton,
  DeleteButton,
  FormDataConsumer,
  required,
  useTranslate,
  useQuery
} from 'react-admin'
import { TreeView, TreeItem } from '@material-ui/lab'
// import TreeItem from '@material-ui/core/TreeItem'

import { TrainingIcon } from './Training'
import { topicChoices, slugify } from '../utils'

export const ServiceTagIcon = BuildIcon

const validateRequired = required()

const ServiceTagTitle = ({ record }) => <span>{record.name}</span>

const NameInput = props => {
  const form = useForm()
  return <TextInput onChange={event => form.change('slug', slugify(event.target.value))} {...props} />
}

const CreateRelatedTagButton = withStyles({ button: { marginTop: '1em' } })(({ classes, record }) => (
  <Button
    className={classes.button}
    // variant='contained'
    component={Link}
    to={{
      pathname: '/ServiceTag/create',
      state: { record: { parent: { id: record.id }, topic: record.topic } }
    }}
    label='resources.ServiceTag.buttons.createTag'
  >
    <BuildIcon />
  </Button>
))

const CreateRelatedTrainingButton = withStyles({ button: { marginTop: '1em' } })(({ classes, record }) => (
  <Button
    className={classes.button}
    // variant='contained'
    component={Link}
    to={{
      pathname: '/Training/create',
      state: { record: { serviceTag: { id: record.id } } }
    }}
    label='resources.ServiceTag.buttons.createTraining'
  >
    <TrainingIcon />
  </Button>
))

export const ServiceTagList = props => (
  <List
    filterDefaultValues={{ parent: null }}
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <SelectField source='topic' choices={topicChoices} />
      {/* <ReferenceField source='parent.id' reference='ServiceTag'>
        <TextField source='name' />
      </ReferenceField> */}
      <TextField source='name' />
      <FunctionField label='resources.ServiceTag.fields.children' render={record => record.tags.length} />
      <FunctionField label='resources.ServiceTag.fields.trainings' render={record => record.trainings.length} />
      <FunctionField label='resources.ServiceTag.fields.quotationRequests' render={record => record.quotationRequests.length} />
    </Datagrid>
  </List>
)

function MinusSquare (props) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z' />
    </SvgIcon>
  )
}

function PlusSquare (props) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  )
}

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3
    }
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${theme.palette.text.primary}`
  }
}))((props) => <TreeItem {...props} />)

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
})

const TreeInput = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const { data, loading, error } = useQuery({
    type: 'getList',
    resource: 'ServiceTag',
    payload: {
      filter: {},
      pagination: { page: 1, perPage: 1000 },
      sort: {
        field: 'id',
        order: 'DESC'
      }
    }
  })

  React.useEffect(() => {
    let itemsById = {}
    const getExpandedIds = id => {
      if (!itemsById[id]) return []
      return [id, ...((itemsById[id].parent && itemsById[id].parent.id) ? getExpandedIds(itemsById[id].parent.id) : [itemsById[id].topic])]
    }

    const onCompleted = (data) => {
      setExpanded(e => [...e, ...getExpandedIds(props.input.value)])
    }
    if (onCompleted && !loading && !error) {
      itemsById = data.reduce((prev, next) => {
        prev[next.id] = next
        return prev
      }, {})
      onCompleted(data)
    }
  }, [loading, data, error, props.input.value])

  const [expanded, setExpanded] = React.useState([])

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  if (loading || !data) return null
  const rootElements = data.filter(c => !c.parent)
  const buildDataTree = items => {
    items.forEach(e => {
      e.children = e.tagsIds.map(id => {
        const item = data.find(c => c.id === id)
        return item
      })
      buildDataTree(e.children)
    })
  }

  buildDataTree(rootElements)
  const elementsByTopic = rootElements.reduce((prev, curr) => {
    if (!prev[curr.topic]) {
      prev[curr.topic] = []
    }
    prev[curr.topic].push(curr)
    return prev
  }, {})

  const renderTree = items => {
    return (
      <>
        {items.map((e, index) => (
          <StyledTreeItem nodeId={e.id} label={`${e.name}`} key={e.id} onLabelClick={() => props.input.onChange(e.id)}>
            {Array.isArray(e.children) && e.children.length ? renderTree(e.children) : null}
          </StyledTreeItem>
        ))}
      </>
    )
  }
  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      defaultEndIcon={null}
      expanded={expanded}
      selected={props.input.value}
      onNodeToggle={handleToggle}
      onNodeSelect={(e) => console.log(e)}
    >
      {Object.keys(elementsByTopic).map(topic => (
        <StyledTreeItem nodeId={topic} label={translate(topicChoices.find(choice => choice.id === topic).name)} key={topic}>
          {renderTree(elementsByTopic[topic])}
        </StyledTreeItem>
      ))}
    </TreeView>
  )
}

export const ServiceTagEdit = props => {
  return (
    <Edit {...props} title={<ServiceTagTitle />}>
      <TabbedForm>
        <FormTab label='resources.ServiceTag.tabs.general'>
          <TextInput source='name' validate={validateRequired} fullWidth />
          <TextInput source='slug' validate={validateRequired} fullWidth />
          <SelectInput source='topic' choices={topicChoices} validate={validateRequired} />
          <ReferenceField source='parent.id' reference='ServiceTag'>
            <TextField source='name' />
          </ReferenceField>
          <ReferenceInput source='parent.id' reference='ServiceTag' fullWidth perPage={1000}>
            <TreeInput />
            {/* <AutocompleteInput
              allowEmpty {...props} optionText={record => {
                console.log(record)
                return `${translate(topicChoices.find(choice => choice.id === record.topic).name)} > ${record.name}`
              }}
            /> */}
          </ReferenceInput>          
        </FormTab>

        <FormTab label='resources.ServiceTag.tabs.content'>
        <RichTextInput source='description' toolbar={[['bold', 'link', { list: 'bullet' }, 'clean']]} validate={validateRequired} />
          <ReferenceArrayInput source='faqsIds' reference='ServiceTagFaq' perPage={1000} addLabel={false} fullWidth>
            <AutocompleteArrayInput optionText='question' />
          </ReferenceArrayInput>
          <RichTextInput source='seoContent' toolbar={[{ header: [2, 3, 4, false] }, ['bold', 'italic', 'underline'], [{ list: 'bullet' }, { list: 'ordered' }], ['clean']]} />
        </FormTab>

        <FormTab label='resources.ServiceTag.tabs.tags'>
          <ReferenceManyField target='parent.id' reference='ServiceTag' addLabel={false} fullWidth>
            <Datagrid>
              <TextField source='name' />
              {/* <FunctionField label='resources.ServiceTag.fields.children' render={record => record.tags.length} /> */}
              <EditButton redirect={() => props.location.basePath} />
              <DeleteButton redirect={() => props.location.basePath} />
            </Datagrid>
          </ReferenceManyField>
          <CreateRelatedTagButton />
        </FormTab>

        <FormTab label='resources.ServiceTag.tabs.trainings'>
          <ReferenceManyField target='serviceTag.id' reference='Training' addLabel={false} fullWidth>
            <Datagrid>
              <ReferenceField source='provider.id' reference='Provider'>
                <TextField source='name' />
              </ReferenceField>
              <FunctionField source='pricePerParticipant' render={record => `${record.pricePerParticipant / 100} €`} />
              <FunctionField label='resources.Training.fields.eventCount' render={record => record.events.length} />
              <EditButton redirect={() => props.location.basePath} />
              <DeleteButton redirect={() => props.location.basePath} />
            </Datagrid>
          </ReferenceManyField>
          <CreateRelatedTrainingButton />
        </FormTab>

        <FormTab label='resources.ServiceTag.tabs.serviceQuotationRequests'>
          <ReferenceManyField target='serviceTag.id' reference='ServiceQuotationRequest' addLabel={false} fullWidth>
            <Datagrid>
              <DateField source='createdAt' locales='de-DE' showTime />
              <ReferenceField source='user.id' reference='User'>
                <FunctionField render={record => `${record.forename} ${record.surname}`} />
              </ReferenceField>
              <EditButton redirect={() => props.location.basePath} />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>

        <FormTab label='resources.ServiceTag.tabs.requirements'>
          <ReferenceArrayInput source='requirementsIds' reference='Requirement' perPage={1000} addLabel={false} fullWidth>
            <AutocompleteArrayInput optionText='title' />
          </ReferenceArrayInput>
        </FormTab>

        <FormTab label='resources.ServiceTag.tabs.providers'>
          <ReferenceArrayField reference='Provider' source='providersIds' fullWidth addLabel={false}>
            <Datagrid>
              <TextField source='name' />
              <ReferenceField source='address.id' reference='Address' label='PLZ' link={false}>
                <TextField source='postalCode' />
              </ReferenceField>
              <EditButton />
            </Datagrid>
          </ReferenceArrayField>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const ServiceTagCreate = props => (
  <Create {...props}>
    <SimpleForm redirect='edit'>
      <FormDataConsumer fullWidth>
        {formDataProps => (
          <NameInput source='name' validate={validateRequired} {...formDataProps} />
        )}
      </FormDataConsumer>
      {/* <TextInput source='name' validate={validateRequired} fullWidth /> */}
      <TextInput source='slug' validate={validateRequired} fullWidth />
      <SelectInput source='topic' choices={topicChoices} validate={validateRequired} />
      <RichTextInput source='description' toolbar={[['bold', 'link', { list: 'bullet' }, 'clean']]} validate={validateRequired} />
      <ReferenceInput source='parent.id' reference='ServiceTag' fullWidth>
        <AutocompleteInput allowEmpty />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
