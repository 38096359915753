import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'
import RoomIcon from '@material-ui/icons/Room'
import {
  EditButton,
  SaveButton,
  Toolbar,
  Create,
  Edit,
  List,
  Pagination,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  SimpleForm,
  TabbedForm,
  FormTab,
  TextInput,
  required,
  regex
} from 'react-admin'
import { slugify } from '../utils'

export const AddressIcon = RoomIcon

export const validatePostalCode = [required(), regex(/^\d{5}$/, 'validation.postalCode')]
const validateRequired = required()

const AddressTitle = ({ record }) => <span>{record.name}</span>

const SaveWithSlugButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm()
  const handleClick = useCallback(() => {
    const { city, street, streetNumber } = form.getState().values
    form.change('slug', slugify(`${city} ${street} ${streetNumber}`))
    handleSubmitWithRedirect('edit')
  }, [form, handleSubmitWithRedirect])

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
}

const AddressToolbar = props => (
  <Toolbar {...props}>
    <SaveWithSlugButton />
  </Toolbar>
)

export const AddressList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <TextField source='street' />
      <TextField source='streetNumber' />
      <TextField source='postalCode' />
      <TextField source='city' />
      <FunctionField label='resources.Address.fields.trainingEvents' render={record => record.trainingEvents.length} />
    </Datagrid>
  </List>
)

export const AddressEdit = props => (
  <Edit {...props} title={<AddressTitle />}>
    <TabbedForm>
      <FormTab label='resources.Address.tabs.general'>
        <TextInput source='name' validate={validateRequired} fullWidth />
        <TextInput source='street' validate={validateRequired} fullWidth />
        <TextInput source='streetNumber' validate={validateRequired} />
        <TextInput source='additionalAddress' fullWidth />
        <TextInput source='postalCode' validate={validatePostalCode} />
        <TextInput source='city' validate={validateRequired} />
        <TextInput source='slug' validate={validateRequired} />
      </FormTab>

      <FormTab label='resources.Address.tabs.trainingEvents'>
        <ReferenceManyField target='address.id' reference='TrainingEvent' addLabel={false} fullWidth>
          <Datagrid>
            <DateField source='startDate' locales='de-DE' />
            <DateField source='endDate' locales='de-DE' />
            <ReferenceField source='training.id' reference='Training'>
              <ReferenceField source='serviceTag.id' reference='ServiceTag'>
                <TextField source='name' />
              </ReferenceField>
            </ReferenceField>
            <ReferenceField source='training.id' reference='Training'>
              <ReferenceField source='provider.id' reference='Provider'>
                <TextField source='name' />
              </ReferenceField>
            </ReferenceField>
            <EditButton redirect={() => props.location.basePath} />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>

      <FormTab label='resources.Address.tabs.providers'>
        <ReferenceManyField target='address.id' reference='Provider' addLabel={false} fullWidth>
          <Datagrid>
            <TextField source='name' />
            <EditButton redirect={() => props.location.basePath} />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>

  </Edit>
)

export const AddressCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<AddressToolbar />}>
      <TextInput source='name' validate={validateRequired} fullWidth />
      <TextInput source='street' validate={validateRequired} fullWidth />
      <TextInput source='streetNumber' validate={validateRequired} />
      <TextInput source='additionalAddress' fullWidth />
      <TextInput source='postalCode' validate={validatePostalCode} />
      <TextInput source='city' validate={validateRequired} />
    </SimpleForm>
  </Create>
)
