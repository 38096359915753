import englishMessages from 'ra-language-english'
import germanMessages from 'ra-language-german'
import treeGermanMessages from 'ra-tree-language-german'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { mergeTranslations } from 'react-admin'

const domainMessages = {
  de: {
    resources: {
      User: {
        name: 'Benutzer',
        fields: {
          name: 'Name',
          forename: 'Vorname',
          surname: 'Nachname',
          email: 'E-Mail',
          phone: 'Telefonnummer',
          'company.id': 'Unternehmen',
          createdAt: 'Registriert am',
          verified: 'Verifiziert',
          serviceQuotationRequests: 'Anfragen',
          trainingBookings: 'Gebuchte Schulungen',
          ratings: 'Bewertungen',
          'billingAddress.id': 'Rechnungsaddresse',
          verificationLink: 'Verifizierungslink'
        },
        tabs: {
          general: 'Allgemein',
          trainingBookings: 'Gebuchte Schulungen',
          serviceQuotationRequests: 'Anfragen',
          ratings: 'Bewertungen',
          billingAddress: 'Rechnungsadresse'
        }
      },
      Company: {
        name: 'Unternehmen',
        fields: {
          'admin.id': 'Benutzer',
          requirements: 'Anforderungen',
          createdAt: 'Registriert am',
          'requirementCriterion.id': 'Anforderungskriterien'
        },
        tabs: {
          general: 'Allgemein',
          requirements: 'Anforderungen'
        }
      },
      CompanyRequirement: {
        name: 'Unternehmensanforderung |||| Unternehmensanforderungen',
        fields: {
          'company.id': 'Unternehmen',
          'requirement.id': 'Anforderung',
          compliedAt: 'Erfüllt am',
          createdAt: 'Erstellt am'
        }
      },
      RequirementCriterion: {
        name: 'Anforderungskriterium |||| Anforderungskriterien',
        fields: {
          employeeCount: 'Mitarbeiteranzahl',
          dangerousSubstances: 'Gefahrstoffe',
          dangerousGoods: 'Gefahrgut',
          industrialTrucks: 'Flurförderzeuge',
          workAtHeight: 'Arbeit in Höhen',
          cranes: 'Krane',
          poweredDoors: 'Türen und Tore',
          passengerElevators: 'Personenaufzüge',
          freightElevators: 'Lastenaufzüge',
          storageEquipment: 'Lagereinrichtungen',
          ladders: 'Leitern und Tritte',
          seriouslyDisabledPersons: 'Behinderte',
          adolescents: 'Jugendliche',
          healthManagement: 'Gesundheitsmanagement',
          qualityManagementSystem: 'Qualitätsmanagementsystem',
          environmentalManagementSystem: 'Umweltmanagementsystem',
          energyManagementSystem: 'Energiemanagementsystem',
          healthAndSafetyManagementSystem: 'Arbeitsmanagementsystem',
          'company.id': 'Unternehmen',
          'requirement.id': 'Anforderung'
        },
        tabs: {
          general: 'Allgemein',
          company: 'Unternehmen',
          requirement: 'Anforderung'
        }
      },
      Requirement: {
        name: 'Anforderung |||| Anforderungen',
        fields: {
          title: 'Titel',
          topic: 'Thema',
          headline: 'Überschrift',
          timeInterval: 'Prüfungsintervall (in Tagen)',
          isIntervalMandatory: 'Pflicht',
          fine: 'Bußgeld',
          riskProbabilityIndex: 'Eintrittswahrscheinlichkeit',
          damageIndex: 'Schadensausmaß',
          effortIndex: 'Umsetzungsaufwand',
          'criterion.id': 'Kriterien',
          filesIds: 'Dokumente',
          serviceTags: 'Dienstleistungen',
          serviceTagsIds: 'Dienstleistungen',
          companyRequirements: 'Unternehmen'
        },
        tabs: {
          general: 'Allgemein',
          assessment: 'Bewertung',
          criterion: 'Kriterien',
          serviceTags: 'Dienstleistungen',
          files: 'Dateien',
          companyRequirements: 'Unternehmen'
        }
      },
      Address: {
        name: 'Adresse |||| Adressen',
        fields: {
          name: 'Name',
          street: 'Straße',
          streetNumber: 'Hausnummer',
          additionalAddress: 'Zusätzliche Adresse',
          postalCode: 'Postleitzahl',
          city: 'Ort',
          country: 'Land',
          trainingEvents: 'Schulungstermine'
        },
        tabs: {
          general: 'Allgemein',
          trainingEvents: 'Schulungstermine',
          providers: 'Dienstleister'
        }
      },
      Provider: {
        name: 'Dienstleister',
        fields: {
          'address.id': 'Adresse',
          invoiceMail: 'Email für Rechnungen',
          termsAndConditionsUrl: 'Link zu den AGB',
          trainings: 'Schulungen',
          serviceQuotations: 'Angebote',
          ratings: 'Bewertungen',
          commission: 'Provision',
          serviceTagsIds: 'Dienstleistungen',
          radius: 'Tätigkeitsradius (km)',
          createdAt: 'Registriert am',
          phone: 'Telefonnummer',
          comments: 'Kommentar / Notiz'
        },
        tabs: {
          general: 'Allgemein',
          address: 'Adresse',
          serviceTags: 'Dienstleistungen',
          trainings: 'Schulungen',
          serviceQuotations: 'Angebote',
          ratings: 'Bewertungen'
        },
        buttons: {
          createTraining: 'Schulung erstellen',
          createServiceQuotation: 'Angebot erstellen'
        }
      },
      Training: {
        name: 'Schulung |||| Schulungen',
        fields: {
          'provider.id': 'Dienstleister',
          'serviceTag.id': 'Dienstleistung',
          pricePerParticipant: 'Preis pro Teilnehmer',
          maxParticipantCount: 'Maximale Teilnehmeranzahl',
          isFinalExamRequired: 'Prüfung erforderlich',
          acquiredQualification: 'Abschlussqualifikation',
          agendaDescription: 'Was Sie in der Schulung erwartet',
          agenda: 'Schulungsinhalte',
          targetAudience: 'Zielgruppe',
          participationRequirements: 'Teilnahmevoraussetzungen',
          amenities: 'Zusatzleistungen',
          eventCount: 'Termine'
        },
        amenities: {
          catering: 'Verpflegung',
          examinationDocuments: 'Elektronische Arbeitsunterlagen'
        },
        tabs: {
          general: 'Allgemein',
          content: 'Inhalt',
          events: 'Termine'
        },
        buttons: {
          createTrainingEvent: 'Termin erstellen'
        }
      },
      TrainingEvent: {
        name: 'Schulungstermin |||| Schulungstermine',
        fields: {
          'address.id': 'Adresse',
          'training.id': 'Schulung',
          startDate: 'Beginn',
          endDate: 'Ende',
          total: 'Gesamtpreis'
        },
        tabs: {
          general: 'Allgemein',
          bookings: 'Buchungen'
        }
      },
      TrainingBooking: {
        name: 'Schulungsbuchung |||| Schulungsbuchungen',
        fields: {
          'user.id': 'Benutzer',
          'event.id': 'Schulungstermin',
          participantCount: 'Teilnehmer',
          totalPrice: 'Gesamtpreis',
          createdAt: 'Buchungsdatum',
          invoicedAt: 'Rechnung gestellt am',
        },
        tabs: {
          general: 'Allgemein'
        }
      },
      ServiceQuotationRequest: {
        name: 'Angebotsanfrage |||| Angebotsanfragen',
        fields: {
          id: 'Kennnummer',
          'user.id': 'Benutzer',
          'serviceTag.id': 'Dienstleistung',
          createdAt: 'Datum',
          quotations: 'Angebote',
          state: 'Status',
          comment: 'Kommentar',
          invoicedAt: 'Rechnung gestellt am',
          invoiced: 'Rechnung gestellt',
          accepted: 'Angenommen',
          commisionTotal: 'Provisionssumme',
          acceptedQuotations: 'Angenommene Angebote'
        },
        tabs: {
          general: 'Allgemein',
          quotations: 'Angebote'
        },
        buttons: {
          createQuotation: 'Angebot erstellen'
        }

      },
      ServiceQuotation: {
        name: 'Angebot |||| Angebote',
        fields: {
          price: 'Preis',
          includedAdditionalCosts: 'Enthaltene Nebenkosten',
          'provider.id': 'Dienstleister',
          'serviceTag.id': 'Dienstleistung',
          'quotationRequest.id': 'Angebotsanfrage',
          createdAt: 'Erstellt am',
          acceptedAt: 'Angenommen am',
          rejectedAt: 'Abgelehnt am',
          state: 'Status',
          file: 'Angebot (PDF)',
          commission: 'Provisionssatz',
          commisionTotal: 'Provisionssumme'
        },
        tabs: {
          general: 'Allgemein'
        }
      },
      Regulation: {
        name: 'Vorschrift |||| Vorschriften',
        fields: {
          abbreviation: 'Abkürzung'
        }
      },
      ServiceTag: {
        name: 'Dienstleistung |||| Dienstleistungen',
        fields: {
          'parent.id': 'Übergeordnete Dienstleistung',
          tags: 'Untergeordnete Dienstleistungen',
          description: 'Beschreibung',
          topic: 'Thema',
          trainings: 'Schulungen',
          quotationRequests: 'Anfragen',
          requirementsIds: 'Anforderungen',
          faqsIds: 'FAQs',
          children: 'Unterkategorien',
          seoContent: 'SEO'
        },
        buttons: {
          createTag: 'Erstellen',
          createTraining: 'Schulung erstellen'
        },
        tabs: {
          general: 'Allgemein',
          content: 'Inhalt',
          tags: 'Dienstleistungen',
          trainings: 'Schulungen',
          serviceQuotationRequests: 'Angebotsanfragen',
          requirements: 'Anforderungen',
          providers: 'Dienstleister'
        }
      },
      ServiceTagFaq: {
        name: 'FAQ |||| FAQs',
        fields: {
          question: 'Frage',
          answer: 'Antwort'
        },
        buttons: {
          createTag: 'Erstellen',
          createTraining: 'Schulung erstellen'
        },
        tabs: {
        }
      },
      Rating: {
        name: 'Bewertung |||| Bewertungen',
        fields: {
          'user.id': 'Benutzer',
          'provider.id': 'Dienstleister',
          costEffectiveness: 'Preis / Leistung',
          reliability: 'Zuverlässigkeit',
          quality: 'Qualität',
          comment: 'Kommentar'
        }
      },
      File: {
        name: 'Datei |||| Dateien',
        fields: {
        }
      }
    },
    validation: {
      postalCode: 'Die PLZ muss aus fünf Ziffern bestehen'
    },
    topics: {
      occupationalSafety: 'Arbeitssicherheit',
      occupationalMedicine: 'Arbeitsmedizin',
      fireSafety: 'Brandschutz',
      privacy: 'Datenschutz',
      dangerousGoods: 'Gefahrgut',
      dangerousSubstances: 'Gefahrstoffe',
      certification: 'Zertifizierung',
      miscellaneous: 'Sonstiges'
    },
    words: {
      days: 'Tage',
      unassigned: 'Nicht zugewiesen'
    },
    occurrenceProbability: {
      nearlyImpossible: 'Praktisch unmöglich',
      unlikely: 'Unwahrscheinlich',
      rarely: 'Selten',
      occasionally: 'Gelegentlich',
      frequently: 'Häufig'
    },
    serviceQuotationRequestStates: {
      open: 'Offen',
      processing: 'In Bearbeitung',
      cancelled: 'Abgebrochen',
      closed: 'Abgeschlossen'
    },
    serviceQuotationStates: {
      pending: 'Ausstehend',
      rejected: 'Abgelehnt',
      accepted: 'Angenommen'
    }
  },
  en: {
    resources: {
      Training: {
        fields: {
          'provider.id': 'Provider'
        }
      }
    }
  }
}

const germanTranlsation = mergeTranslations(germanMessages, treeGermanMessages)

germanTranlsation.ra.boolean.null = 'Unausgefüllt'
germanTranlsation.ra.page.empty = 'Noch keine Einträge'
germanTranlsation.ra.page.invite = 'Möchtest Du Einträge erstellen?'

const messages = {
  en: { ...englishMessages, ...domainMessages.en },
  de: { ...germanTranlsation, ...domainMessages.de }
}

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'de')

export default i18nProvider
