import React from 'react'
import PropTypes from 'prop-types'
import { FileField } from 'react-admin'

class S3FileField extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      url: null
    }
  }

  async componentDidMount () {
    const { record = {}, source } = this.props
    const request = (await fetch(process.env.REACT_APP_FILE_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ key: record[source] })
    }))
    this.setState(await request.json())
  }

  render (props) {
    if (this.state.url == null) return null
    return <FileField record={this.state} source='url' title='Datei herunterladen' target='_blank' {...props} />
  }
}

S3FileField.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
}

export default S3FileField
