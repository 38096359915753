import React from 'react'
import SchoolIcon from '@material-ui/icons/School'
import RichTextInput from 'ra-input-rich-text'
// import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Create,
  Edit,
  List,
  Pagination,
  Link,
  TabbedForm,
  FormTab,
  EditButton,
  DeleteButton,
  Datagrid,
  DateField,
  TextField,
  ReferenceField,
  FunctionField,
  ReferenceManyField,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  CheckboxGroupInput,
  AutocompleteInput,
  required
} from 'react-admin'

import { TrainingEventIcon } from './TrainingEvent'

export const TrainingIcon = SchoolIcon

const validateRequired = required()

const amenityChoices = [
  { id: 'CATERING', name: 'resources.Training.amenities.catering' },
  { id: 'EXAMINATION_DOCUMENTS', name: 'resources.Training.amenities.examinationDocuments' }
]

const CreateRelatedEventButton = withStyles({ button: { marginTop: '1em' } })(({ classes, record }) => (
  <Button
    className={classes.button}
    // variant='contained'
    component={Link}
    to={{
      pathname: '/TrainingEvent/create',
      state: { record: { training: { id: record.id } } }
    }}
    label='resources.Training.buttons.createTrainingEvent'
  >
    <TrainingEventIcon />
  </Button>
))

export const TrainingList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <ReferenceField source='serviceTag.id' reference='ServiceTag'>
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField source='provider.id' reference='Provider'>
        <TextField source='name' />
      </ReferenceField>
      <FunctionField render={record => `${record.pricePerParticipant / 100} €`} />
      <FunctionField label='resources.Training.fields.eventCount' render={record => record.events.length} />
    </Datagrid>
  </List>
)

export const TrainingEdit = props => {
  return (
    <Edit {...props}>
      <TabbedForm>

        <FormTab label='resources.Training.tabs.general'>
          <ReferenceInput source='serviceTag.id' reference='ServiceTag' perPage={1000} fullWidth>
            <AutocompleteInput validate={validateRequired} />
          </ReferenceInput>
          <NumberInput source='pricePerParticipant' format={v => v / 100} parse={v => parseInt((parseFloat(v) * 100).toFixed())} validate={validateRequired} />
          <ReferenceInput source='provider.id' reference='Provider' fullWidth>
            <AutocompleteInput validate={validateRequired} />
          </ReferenceInput>
        </FormTab>

        <FormTab label='resources.Training.tabs.content'>
          <BooleanInput source='isFinalExamRequired' validate={validateRequired} />
          <CheckboxGroupInput source='amenities' choices={amenityChoices} />
          <NumberInput source='maxParticipantCount' step={1} />
          <RichTextInput source='agendaDescription' toolbar={[['bold']]} validate={validateRequired} />
          <RichTextInput source='agenda' toolbar={[[{ header: [4, false] }], [{ list: 'bullet' }]]} validate={validateRequired} />
          <RichTextInput source='acquiredQualification' toolbar={[['bold'], [{ list: 'bullet' }]]} validate={validateRequired} />
          <RichTextInput source='targetAudience' toolbar={[['bold'], [{ list: 'bullet' }]]} />
          <RichTextInput source='participationRequirements' toolbar={[['bold'], [{ list: 'bullet' }]]} />
        </FormTab>

        <FormTab label='resources.Training.tabs.events'>
          <ReferenceManyField target='training.id' reference='TrainingEvent' addLabel={false} perPage={1000} fullWidth>
            <Datagrid>
              <DateField source='startDate' locales='de-DE' showTime />
              <DateField source='endDate' locales='de-DE' showTime />
              <ReferenceField source='address.id' reference='Address'>
                <FunctionField render={record => `${record.street} ${record.streetNumber}, ${record.city}`} />
              </ReferenceField>
              <EditButton redirect={() => props.location.basePath} />
              <DeleteButton redirect={() => props.location.basePath} />
            </Datagrid>
          </ReferenceManyField>
          <CreateRelatedEventButton />
        </FormTab>

      </TabbedForm>
    </Edit>
  )
}

export const TrainingCreate = props => (
  <Create {...props}>
    <TabbedForm>

      <FormTab label='resources.Training.tabs.general'>
        <ReferenceInput source='serviceTag.id' reference='ServiceTag' perPage={1000} fullWidth>
          <AutocompleteInput validate={validateRequired} />
        </ReferenceInput>
        <NumberInput source='pricePerParticipant' format={v => v / 100} parse={v => parseInt((parseFloat(v) * 100).toFixed())} validate={validateRequired} />
        <ReferenceInput source='provider.id' reference='Provider' perPage={1000} fullWidth>
          <AutocompleteInput validate={validateRequired} />
        </ReferenceInput>
      </FormTab>

      <FormTab label='resources.Training.tabs.content'>
        <BooleanInput source='isFinalExamRequired' />
        <CheckboxGroupInput source='amenities' choices={amenityChoices} />
        <NumberInput source='maxParticipantCount' step={1} />
        <RichTextInput source='agendaDescription' toolbar={[['bold']]} validate={validateRequired} />
        <RichTextInput source='agenda' toolbar={[[{ header: [4, false] }], [{ list: 'bullet' }]]} validate={validateRequired} />
        <RichTextInput source='acquiredQualification' toolbar={[['bold'], [{ list: 'bullet' }]]} validate={validateRequired} />
        <RichTextInput source='targetAudience' toolbar={[['bold'], [{ list: 'bullet' }]]} />
        <RichTextInput source='participationRequirements' toolbar={[['bold'], [{ list: 'bullet' }]]} />
      </FormTab>

    </TabbedForm>
  </Create>
)
