import React from 'react'
import EventIcon from '@material-ui/icons/Event'
import {
  Create,
  Edit,
  List,
  Pagination,
  EditButton,
  Datagrid,
  ReferenceField,
  DateField,
  TextField,
  NumberField,
  FunctionField,
  ReferenceManyField,
  SimpleForm,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  required
} from 'react-admin'

export const TrainingEventIcon = EventIcon

const validateRequired = required()

export const TrainingEventList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <DateField source='startDate' locales='de-DE' showTime />
      <DateField source='endDate' locales='de-DE' showTime />
      <ReferenceField source='address.id' reference='Address'>
        <FunctionField render={record => `${record.name} - ${record.street} ${record.streetNumber}, ${record.city}`} />
      </ReferenceField>
    </Datagrid>
  </List>
)

export const TrainingEventEdit = props => {
  const redirect = (basePath, id, data) => data.training && data.training.id ? `/Training/${data.training.id}/2` : 'edit'

  return (
    <Edit {...props}>
      <TabbedForm redirect={redirect}>
        <FormTab label='resources.TrainingEvent.tabs.general'>
          <ReferenceInput source='address.id' reference='Address' validate={validateRequired} perPage={1000} fullWidth>
            <AutocompleteInput optionText={record => `${record.name} - ${record.city} - ${record.street} ${record.streetNumber}`} />
          </ReferenceInput>
          <DateTimeInput source='startDate' validate={validateRequired} />
          <DateTimeInput source='endDate' validate={validateRequired} />
          <ReferenceField source='training.id' reference='Training'>
            <ReferenceField source='serviceTag.id' reference='ServiceTag' link={(record, reference) => `/Training/${record.id}`}>
              <TextField source='name' />
            </ReferenceField>
          </ReferenceField>
        </FormTab>

        <FormTab label='resources.TrainingEvent.tabs.bookings'>
          <ReferenceManyField target='event.id' reference='TrainingBooking' addLabel={false} fullWidth>
            <Datagrid>
              <DateField source='createdAt' locales='de-DE' showTime />
              <ReferenceField source='user.id' reference='User'>
                <FunctionField render={record => `${record.forename} ${record.surname}`} />
              </ReferenceField>
              <NumberField source='participantCount' locales='de-DE' />
              <FunctionField label='resources.TrainingEvent.fields.total' render={record => `${record.participantCount * record.pricePerParticipant / 100} €`} />
              <EditButton redirect={() => props.location.basePath} />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const TrainingEventCreate = props => {
  const { location } = props
  const training = location.state && location.state.record && location.state.record.training ? location.state.record.training : {}
  const redirect = training ? `/Training/${training.id}/2` : 'edit'
  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ training }}
        redirect={redirect}
      >
        <ReferenceInput source='address.id' reference='Address' validate={validateRequired} perPage={1000} fullWidth>
          <SelectInput optionText={record => `${record.name} - ${record.city} - ${record.street} ${record.streetNumber}`} />
        </ReferenceInput>
        <DateTimeInput source='startDate' validate={validateRequired} />
        <DateTimeInput source='endDate' validate={validateRequired} />
      </SimpleForm>
    </Create>
  )
}
