import React, { useCallback } from 'react'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import { useForm } from 'react-final-form'
import cuid from 'cuid'
import {
  Create,
  Edit,
  List,
  Pagination,
  Datagrid,
  ReferenceField,
  TextField,
  SelectField,
  FunctionField,
  DateField,
  FileField,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  FileInput,
  SimpleForm,
  SaveButton,
  Toolbar,
  Filter,
  required,
  DateInput
} from 'react-admin'
import { subMonths, startOfMonth, lastDayOfMonth } from 'date-fns'

import CalcSum from '../fields/CalcSum'
import S3ServiceQuotationFileField from '../fields/S3ServiceQuotationFileField'
import { serviceQuotationStateChoices } from '../utils'

export const ServiceQuotationIcon = LocalOfferIcon

const validateRequired = required()

const ServiceQuotationTitle = ({ record }) => <span>Angebot {new Date(record.createdAt).toLocaleString('de-DE')}</span>

const SaveWithFileButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm()

  const handleClick = useCallback(async () => {
    const { file, quotationRequest } = form.getState().values
    if (quotationRequest != null) {
      const request = (await fetch(process.env.REACT_APP_QUOTATION_UPLOAD_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filename: `${cuid()}${file.rawFile.path.substr(file.rawFile.path.lastIndexOf('.'))}`,
          quotationRequestId: quotationRequest.id
        })
      }))
      const { url, key } = await request.json()

      // upload pdf to aws
      const res = await fetch(url, {
        method: 'PUT',
        body: file.rawFile,
        headers: { 'Content-Type': `${file.rawFile.type}; charset="utf-8"` }
      })
      if (res.ok) {
        form.change('file', undefined)
        form.change('filename', key)

        handleSubmitWithRedirect(`/ServiceQuotationRequest/${quotationRequest.id}/1`)
      }
    }
  }, [form, handleSubmitWithRedirect])
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
}

const ServiceQuotationCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveWithFileButton />
  </Toolbar>
)

const ServiceQuotationRequestFilter = props => (
  <Filter {...props}>
    <SelectInput label='Status' source='state' choices={serviceQuotationStateChoices} alwaysOn resettable />
    <DateInput label='Leistungszeitraum (ab)' source='acceptedAt_gte' defaultValue={startOfMonth(subMonths(new Date(), 1))} />
    <DateInput label='Leistungszeitraum (bis)' source='acceptedAt_lte' defaultValue={lastDayOfMonth(subMonths(new Date(), 1))} />
  </Filter>
)

export const ServiceQuotationList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<ServiceQuotationRequestFilter />}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <DateField source='createdAt' locales='de-DE' showTime />
      <DateField source='acceptedAt' locales='de-DE' showTime />
      <DateField source='rejectedAt' locales='de-DE' showTime />
      <SelectField source='state' choices={serviceQuotationStateChoices} />
      <ReferenceField source='quotationRequest.id' reference='ServiceQuotationRequest' label='resources.Company.name'>
        <ReferenceField source='user.id' reference='User' label='resources.Company.name'>
          <ReferenceField source='company.id' reference='Company' link={(record, reference) => `/${reference}/${record.company.id}`}>
            <TextField source='name' />
          </ReferenceField>
        </ReferenceField>
      </ReferenceField>
      <ReferenceField source='provider.id' reference='Provider'>
        <TextField source='name' />
      </ReferenceField>
      <FunctionField source='price' render={record => `${record.price / 100} €`} />
    </Datagrid>
  </List>
)

export const ServiceQuotationEdit = props => {
  return (
    <Edit {...props} title={<ServiceQuotationTitle />}>
      <SimpleForm>
        <DateField source='createdAt' locales='de-DE' showTime />
        <DateField source='acceptedAt' locales='de-DE' showTime />
        <DateField source='rejectedAt' locales='de-DE' showTime />
        <SelectInput source='state' choices={serviceQuotationStateChoices} />
        <NumberInput source='price' format={v => v / 100} parse={v => parseInt((parseFloat(v) * 100).toFixed())} validate={validateRequired} />
        <NumberInput source='includedAdditionalCosts' format={v => v / 100} parse={v => parseInt((parseFloat(v) * 100).toFixed())} validate={validateRequired} />
        <ReferenceInput source='provider.id' reference='Provider' fullWidth validate={validateRequired}>
          <AutocompleteInput validate={validateRequired} />
        </ReferenceInput>
        <ReferenceField label='resources.ServiceQuotation.fields.commission' source='provider.id' reference='Provider' link=''>
          <FunctionField render={record => `${record.commission} %`} />
        </ReferenceField>
        <FunctionField source='commisionTotal' render={(record) => <CalcSum record={record} />} />
        <S3ServiceQuotationFileField source='filename' />
        <ReferenceField source='quotationRequest.id' reference='ServiceQuotationRequest'>
          <FunctionField render={record => 'Zur Anfrage'} />
        </ReferenceField>
      </SimpleForm>
    </Edit>
  )
}

export const ServiceQuotationCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<ServiceQuotationCreateToolbar />}>
        <NumberInput source='price' format={v => v / 100} parse={v => parseInt((parseFloat(v) * 100).toFixed())} validate={validateRequired} />
        <NumberInput source='includedAdditionalCosts' format={v => v / 100} parse={v => parseInt((parseFloat(v) * 100).toFixed())} validate={validateRequired} />
        <ReferenceInput source='provider.id' reference='Provider' fullWidth perPage={1000} validate={validateRequired}>
          <AutocompleteInput validate={validateRequired} />
        </ReferenceInput>
        <FileInput source='file' accept='application/pdf'>
          <FileField source='src' title='title' />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}
