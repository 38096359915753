import React from 'react'
import BusinessIcon from '@material-ui/icons/Business'
import {
  Edit,
  List,
  Pagination,
  TabbedForm,
  FormTab,
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  TextInput,
  EditButton,
  required,
  Filter,
  SelectInput,
  SelectField
} from 'react-admin'
import { editorChoices } from '../utils'

export const CompanyIcon = BusinessIcon

const validateRequired = required()

const CompanyTitle = ({ record }) => {
  return <span>{record.name}</span>
}

const CompanyFilter = props => (
  <Filter {...props}>
    <SelectInput label='Editor' source='editor' choices={editorChoices} alwaysOn resettable allowEmpty={false} />
  </Filter>
)

export const CompanyList = props => (
  <List
    {...props}
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<CompanyFilter />}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <SelectField source='editor' choices={editorChoices} />
      <ReferenceField source='admin.id' reference='User'>
        <FunctionField render={record => `${record.forename} ${record.surname}`} />
      </ReferenceField>
      <DateField source='createdAt' locales='de-DE' showTime />
      <FunctionField label='resources.Company.fields.requirements' render={record => record.requirements.length} />
    </Datagrid>
  </List>
)

export const CompanyEdit = props => (
  <Edit {...props} title={<CompanyTitle />}>
    <TabbedForm>
      <FormTab label='resources.Company.tabs.general'>
        <TextInput source='name' fullWidth validate={validateRequired} />
        <SelectInput source='editor' choices={editorChoices} />
        <ReferenceField source='admin.id' reference='User'>
          <FunctionField render={record => `${record.forename} ${record.surname}`} />
        </ReferenceField>
        <ReferenceField source='requirementCriterion.id' reference='RequirementCriterion'>
          <FunctionField render={record => 'Quickcheck'} />
        </ReferenceField>
      </FormTab>

      <FormTab label='resources.Company.tabs.requirements'>
        <ReferenceManyField target='company.id' reference='CompanyRequirement' addLabel={false} fullWidth>
          <Datagrid>
            <ReferenceField source='requirement.id' reference='Requirement'>
              <FunctionField render={record => `${record.title} > ${record.headline}`} />
            </ReferenceField>
            <DateField source='compliedAt' locales='de-DE' />
            <EditButton redirect={() => props.location.basePath} />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)
