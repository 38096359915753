import React from 'react'
import RoomServiceIcon from '@material-ui/icons/RoomService'
import { withStyles } from '@material-ui/core/styles'
import MaterialButton from '@material-ui/core/Button'
import {
  Edit,
  List,
  Pagination,
  Datagrid,
  ReferenceField,
  TextField,
  FunctionField,
  DateField,
  SelectField,
  ReferenceManyField,
  SelectInput,
  TextInput,
  NullableBooleanInput,
  TabbedForm,
  FormTab,
  Button,
  EditButton,
  DeleteButton,
  Link,
  Filter,
  DateInput,
  BooleanField,
  SingleFieldList
} from 'react-admin'
import CalcSum from '../fields/CalcSum'

import { ServiceQuotationIcon } from './ServiceQuotation'
import { serviceQuotationRequestStateChoices, editorChoices, getShortQuotationRequestId } from '../utils'

export const ServiceQuotationRequestIcon = RoomServiceIcon

const CreateRelatedQuotationButton = withStyles({ button: { marginTop: '1em' } })(({ classes, record }) => (
  <Button
    className={classes.button}
    // variant='contained'
    component={Link}
    to={{
      pathname: '/ServiceQuotation/create',
      state: { record: { quotationRequest: { id: record.id } } }
    }}
    label='resources.ServiceQuotationRequest.buttons.createQuotation'
  >
    <ServiceQuotationIcon />
  </Button>
))

const ServiceQuotationRequestTitle = ({ record }) => <span>Angebotsanfrage {new Date(record.createdAt).toLocaleString('de-DE')}</span>

const ServiceQuotationRequestFilter = props => (
  <Filter {...props}>
    <SelectInput label='Editor' source='editor' choices={editorChoices} alwaysOn resettable allowEmpty={false} />
    <SelectInput label='Status' source='state' choices={serviceQuotationRequestStateChoices} alwaysOn resettable />
    <NullableBooleanInput label='Rechnung' source='invoiced' alwaysOn />
  </Filter>
)

export const ServiceQuotationRequestList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    filters={<ServiceQuotationRequestFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <DateField source='createdAt' locales='de-DE' showTime />
      <FunctionField render={record => getShortQuotationRequestId(record.id)} label='resources.ServiceQuotationRequest.fields.id' />
      <SelectField source='state' choices={serviceQuotationRequestStateChoices} />
      <SelectField source='editor' choices={editorChoices} />
      <ReferenceField source='serviceTag.id' reference='ServiceTag'>
        <TextField source='name' />
      </ReferenceField>
      <ReferenceField source='user.id' reference='User'>
        <FunctionField render={record => `${record.forename} ${record.surname}`} />
      </ReferenceField>
      <ReferenceField source='user.id' reference='User' label='resources.Company.name'>
        <ReferenceField
          source='company.id' reference='Company' link={(record, reference) => `/${reference}/${record.company.id}`}
        >
          <TextField source='name' />
        </ReferenceField>
      </ReferenceField>
      <FunctionField label='resources.ServiceQuotationRequest.fields.invoiced' render={record => <BooleanField record={{ invoiced: !!record.invoicedAt }} source='invoiced' />} />
      <FunctionField label='resources.ServiceQuotationRequest.fields.quotations' render={record => record.quotations.length} />
    </Datagrid>
  </List>
)

export const ServiceQuotationRequestEdit = props => {
  return (
    <Edit {...props} title={<ServiceQuotationRequestTitle />}>
      <TabbedForm>
        <FormTab label='resources.ServiceQuotationRequest.tabs.general'>
          <FunctionField render={record => getShortQuotationRequestId(record.id)} label='resources.ServiceQuotationRequest.fields.id' />
          <SelectInput source='state' choices={serviceQuotationRequestStateChoices} />
          <SelectInput source='editor' choices={editorChoices} />
          <DateInput source='invoicedAt' locales='de-DE' />
          <DateField source='createdAt' locales='de-DE' showTime />
          <ReferenceField source='serviceTag.id' reference='ServiceTag'>
            <TextField source='name' />
          </ReferenceField>
          <ReferenceField source='user.id' reference='User'>
            <FunctionField render={record => `${record.forename} ${record.surname}`} />
          </ReferenceField>
          <TextInput source='comment' multiline fullWidth />
          <ReferenceManyField target='quotationRequest.id' reference='ServiceQuotation' addLabel={false}>
            <SingleFieldList>
              <FunctionField render={record => record.state === 'ACCEPTED' && <MaterialButton variant='outlined' color='primary'>Angenommenes Angebot</MaterialButton>} />
            </SingleFieldList>
          </ReferenceManyField>
        </FormTab>

        <FormTab label='resources.ServiceQuotationRequest.tabs.quotations'>
          <ReferenceManyField target='quotationRequest.id' reference='ServiceQuotation' addLabel={false} fullWidth>
            <Datagrid>
              <DateField source='createdAt' locales='de-DE' showTime />
              <ReferenceField source='provider.id' reference='Provider'>
                <TextField source='name' />
              </ReferenceField>
              <FunctionField render={record => `${record.price / 100} €`} />
              <FunctionField label='resources.ServiceQuotationRequest.fields.accepted' render={record => <BooleanField record={{ accepted: record.state === 'ACCEPTED' }} source='accepted' />} />
              <FunctionField label='resources.ServiceQuotationRequest.fields.commisionTotal' render={record => <CalcSum record={record} />} />
              <EditButton redirect={() => props.location.basePath} />
              <DeleteButton redirect={() => props.location.basePath} />
            </Datagrid>
          </ReferenceManyField>
          <CreateRelatedQuotationButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
