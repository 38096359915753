import includes from 'lodash.includes'
import map from 'lodash.map'
import ApolloClient from 'apollo-client'
import buildGraphQLProvider, { buildQuery } from '@unly/ra-data-graphql-prisma'
import { CREATE, UPDATE, GET_LIST } from 'react-admin'
import { diff } from 'deep-diff'
// import { print } from 'graphql/language/printer'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'

import { __schema as schema } from './schema.json'
// import overriddenQueries from './queries'

/**
 * Sanitize data for a mutation UPDATE operation
 *
 * Remove from "data" all blacklisted fields
 * Remove from "data" all fields that weren't updated (equal to previousData)
 */
const sanitizeMutationUpdateData = (data, previousData) => {
  const sanitizedData = {
    id: data.id // The id is required to be converted to a WHERE statement by the query builder
  }

  // Always remove createdAt, updatedAt because they shouldn't be updated
  const blackListedFields = [
    'createdAt',
    'updatedAt'
  ]

  const changes = diff(previousData, data)

  map(changes, change => {
    const fieldName = change.path[0]

    if (!includes(blackListedFields, fieldName)) {
      if (change.path.length === 1) {
        sanitizedData[fieldName] = change.rhs
      } else {
        const dict = change.path.reduceRight((acc, currentValue) => ({ [currentValue]: acc }), change.rhs)
        sanitizedData[fieldName] = dict[fieldName]
      }
    }
    if (fieldName === 'amenities') {
      sanitizedData[fieldName] = data[fieldName]
    }
  })

  return sanitizedData
}

const sanitizeMutationCreateData = data => {
  const sanitizedData = {}

  // Always remove createdAt, updatedAt because they shouldn't be updated
  const blackListedFields = [
    'createdAt',
    'updatedAt'
  ]

  map(data, (value, fieldName) => {
    if (!includes(blackListedFields, fieldName)) {
      sanitizedData[fieldName] = value
    }
  })

  return sanitizedData
}

const sanitizeListFilter = (resourceName, filter = {}) => {
  switch (resourceName) {
    case 'ServiceTag':
    case 'ServiceTagFaq':
    case 'Provider':
    case 'Address': {
      if (filter.q) {
        delete filter.q
        // const { q, ...rest } = filter
        // return { ...rest }
      }
      break    
    }
    case 'ServiceQuotationRequest': {
      if (filter.invoiced != null) {
        if (filter.invoiced) {
          filter.invoicedAt_not = null
        } else {
          filter.invoicedAt = null
        }
        delete filter.invoiced
      }
      break
    }
    default:
      break
  }
  return filter
}

const enhanceBuildQuery = buildQuery => introspectionResults => (fetchType, resourceName, params) => {
  const { data } = params
  // const fragment = get(overriddenQueries, `${resourceName}.${fetchType}`)

  // console.log('introspectionResults', introspectionResults)
  // console.log('fragment', fragment)

  // console.log('fetchType', fetchType)
  // console.log('resourceName', resourceName)
  // console.log('initial params', JSON.stringify(params, null, 2))

  // Step 1 - Sanitize data so that the generated query/mutation is correct (structure/shape)
  switch (fetchType) {
    case UPDATE: {
      const { previousData } = params
      // Override data by removing all non-updated and blacklisted fields
      params.data = sanitizeMutationUpdateData(data, previousData)

      if (resourceName === 'Requirement' || resourceName === 'Provider') {
        if (data.serviceTagsIds) {
          params.data = { ...params.data, serviceTagsIds: data.serviceTagsIds }
        }
        if (data.filesIds) {
          params.data = { ...params.data, filesIds: data.filesIds }
        }
      }

      if (resourceName === 'ServiceTag') {
        if (data.requirementsIds) {
          params.data = { ...params.data, requirementsIds: data.requirementsIds }
        }
        if (data.providersIds) {
          params.data = { ...params.data, providersIds: data.providersIds }
        }
        if (data.faqsIds) {
          params.data = { ...params.data, faqsIds: data.faqsIds }
        }
      }

      break
    }
    case CREATE: {
      if (resourceName === 'ServiceTag') {
        if (data.parent && data.parent.id) {
          params.data = { ...params.data, parent: { connect: { id: data.parent.id } } }
          params.data = sanitizeMutationCreateData(params.data)
          break
        }
      }
      params.data = sanitizeMutationCreateData(data)
      break
    }
    case GET_LIST: {
      params.filter = sanitizeListFilter(resourceName, params.filter)
      break
    }
    default:
      break
  }

  // console.log('queryParams', params)

  const builtQuery = buildQuery(introspectionResults)(fetchType, resourceName, params)

  /**
   * Hacky. Better do this instead
   * @see https://marmelab.com/react-admin/CreateEdit.html#using-onsave-to-alter-the-form-submission-behavior
   */

  switch (fetchType) {
    case CREATE: {
      switch (resourceName) {
        case 'Requirement': {
          const { criterion, serviceTagsIds } = params.data
          if (criterion) {
            if (criterion.employeeCount) {
              if (criterion.employeeCount === '') {
                criterion.employeeCount = null
              }
            }
            params.data.criterion = { create: criterion }
          } else {
            params.data.criterion = { create: {} }
          }

          if (serviceTagsIds) {
            params.data.serviceTags = { connect: serviceTagsIds.map(id => ({ id })) }
            delete params.data.serviceTagsIds
          }

          builtQuery.variables = params
          break
        }
        case 'ServiceTag': {
          const { requirementsIds } = params.data
          if (requirementsIds) {
            params.data.requirements = { connect: requirementsIds.map(id => ({ id })) }
            delete params.data.requirements
          }

          builtQuery.variables = params
          break
        }
        default:
          break
      }
      break
    }
    default:
      break
  }

  // const { query, variables } = builtQuery

  // console.log('builtQuery', builtQuery)
  // console.debug(print(query), '- Variables:', variables, ' using params:', params)

  return builtQuery
}

const errorLink = onError(({ response, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    response.errors = response.errors.map(e => ({ ...e, status: 401 }))
  }
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null
    }
  }
})

const getDataProvider = async uri => buildGraphQLProvider({
  client: new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      authLink,
      createHttpLink({ uri })
    ]),
    cache: new InMemoryCache()
  }),
  buildQuery: enhanceBuildQuery(buildQuery),
  introspection: { schema },
  debug: process.env.NODE_ENV === 'development'
})

export default getDataProvider
