import React from 'react'
import StarHalfIcon from '@material-ui/icons/StarHalf'
import {
  Edit,
  List,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
  FunctionField,
  SimpleForm
} from 'react-admin'

export const RatingIcon = StarHalfIcon

export const RatingList = props => (
  <List {...props}>
    <Datagrid rowClick='edit'>
      <ReferenceField source='user.id' reference='User'>
        <FunctionField render={record => `${record.forename} ${record.surname}`} />
      </ReferenceField>
      <ReferenceField source='provider.id' reference='Provider'>
        <TextField source='name' />
      </ReferenceField>
      <NumberField source='costEffectiveness' />
      <NumberField source='reliability' />
      <NumberField source='quality' />
      <TextField source='comment' />
    </Datagrid>
  </List>
)

export const RatingEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceField source='provider.id' reference='Provider'>
        <TextField source='name' />
      </ReferenceField>
      <NumberField source='costEffectiveness' />
      <NumberField source='reliability' />
      <NumberField source='quality' />
      <TextField source='comment' />
    </SimpleForm>
  </Edit>
)
