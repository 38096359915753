import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  SaveButton,
  Toolbar,
  Create,
  Edit,
  List,
  Pagination,
  Link,
  EditButton,
  DeleteButton,
  Datagrid,
  SimpleForm,
  TabbedForm,
  FormTab,
  ReferenceField,
  TextField,
  ImageField,
  FunctionField,
  NumberField,
  ReferenceManyField,
  DateField,
  TextInput,
  NumberInput,
  ImageInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  required,
  email
} from 'react-admin'

import CloudinaryImageField from '../fields/CloudinaryImageField'
import { TrainingIcon } from './Training'
import { slugify } from '../utils'

export const ProviderIcon = PermContactCalendarIcon

const validateRequired = required()
const validateEmail = [required(), email()]

const ProviderTitle = ({ record }) => {
  return <span>{record.name}</span>
}

const uploadImage = async file => {
  const data = new FormData()
  data.append('file', file)
  data.append('upload_preset', 'providers')

  const upload = await fetch('https://api.cloudinary.com/v1_1/opticert/image/upload', {
    method: 'POST',
    body: data
  })

  const image = await upload.json()
  return image
}

const NameInput = props => {
  const form = useForm()
  return <TextInput onChange={event => form.change('slug', slugify(event.target.value))} {...props} />
}

const SaveWithImageButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm()

  const handleClick = useCallback(async () => {
    const { logo } = form.getState().values
    if (logo == null) return handleSubmitWithRedirect('edit')
    const { public_id: logoId } = await uploadImage(logo.rawFile)

    form.change('logo', undefined)
    form.change('logoId', logoId)

    handleSubmitWithRedirect('edit')
  }, [form, handleSubmitWithRedirect])
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />
}

const CreateRelatedTrainingButton = withStyles({ button: { marginTop: '1em' } })(({ classes, record }) => (
  <Button
    className={classes.button}
    // variant='contained'
    component={Link}
    to={{
      pathname: '/Training/create',
      state: { record: { provider: { id: record.id } } }
    }}
    label='resources.Provider.buttons.createTraining'
  >
    <TrainingIcon />
  </Button>
))

// const CreateRelatedServiceQuotationButton = withStyles({ button: { marginTop: '1em' } })(({ classes, record }) => (
//   <Button
//     className={classes.button}
//     // variant='contained'
//     component={Link}
//     to={{
//       pathname: '/ServiceQuotation/create',
//       state: { record: { provider: { id: record.id } } }
//     }}
//     label='resources.Provider.buttons.createServiceQuotation'
//   >
//     <ServiceQuotationIcon />
//   </Button>
// ))

const ProviderCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveWithImageButton />
  </Toolbar>
)

export const ProviderList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <CloudinaryImageField height='25' source='logoId' />
      <DateField source='createdAt' locales='de-DE' showTime />
      <FunctionField label='resources.Provider.fields.trainings' render={record => record.trainings.length} />
      <FunctionField label='resources.Provider.fields.serviceQuotations' render={record => record.serviceQuotations.length} />
      <FunctionField label='resources.Provider.fields.ratings' render={record => record.ratings.length} />
    </Datagrid>
  </List>
)

export const ProviderEdit = props => (
  <Edit
    {...props}
    title={<ProviderTitle />}
  >
    <TabbedForm>
      <FormTab label='resources.Provider.tabs.general'>
        <DateField source='createdAt' locales='de-DE' showTime />
        <TextInput source='name' fullWidth validate={validateRequired} />
        <TextInput source='slug' validate={validateRequired} />
        <TextInput source='email' type='email' validate={validateEmail} />
        <TextInput source='invoiceMail' type='email' />
        <TextInput source='phone' type='tel' />
        <NumberInput source='commission' validate={validateRequired} />
        <ReferenceInput source='address.id' reference='Address' perPage={1000} fullWidth>
          <AutocompleteInput optionText={record => `${record.name} - ${record.postalCode} ${record.city} - ${record.street} ${record.streetNumber}`} />
        </ReferenceInput>
        <NumberInput source='radius' />
        <CloudinaryImageField source='logoId' />
        <TextInput source='termsAndConditionsUrl' type='url' />
        <TextInput source='comments' multiline fullWidth />
      </FormTab>

      <FormTab label='resources.Provider.tabs.serviceTags'>
        <ReferenceArrayInput source='serviceTagsIds' reference='ServiceTag' perPage={1000} addLabel={false} fullWidth>
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
      </FormTab>

      <FormTab label='resources.Provider.tabs.trainings'>
        <ReferenceManyField target='provider.id' reference='Training' addLabel={false} fullWidth>
          <Datagrid>
            <ReferenceField source='serviceTag.id' reference='ServiceTag'>
              <TextField source='name' />
            </ReferenceField>
            <FunctionField source='pricePerParticipant' render={record => `${record.pricePerParticipant / 100} €`} />
            <FunctionField label='resources.Training.fields.eventCount' render={record => record.events.length} />
            <EditButton redirect={() => props.location.basePath} />
            <DeleteButton redirect={() => props.location.basePath} />
          </Datagrid>
        </ReferenceManyField>
        <CreateRelatedTrainingButton />
      </FormTab>

      <FormTab label='resources.Provider.tabs.serviceQuotations'>
        <ReferenceManyField target='provider.id' reference='ServiceQuotation' addLabel={false} fullWidth>
          <Datagrid>
            <DateField source='createdAt' locales='de-DE' showTime />
            <ReferenceField source='quotationRequest.id' reference='ServiceQuotationRequest'>
              <ReferenceField source='serviceTag.id' reference='ServiceTag'>
                <TextField source='name' />
              </ReferenceField>
            </ReferenceField>
            <FunctionField render={record => `${record.price / 100} €`} />
            <EditButton redirect={() => props.location.basePath} />
            <DeleteButton redirect={() => props.location.basePath} />
          </Datagrid>
        </ReferenceManyField>
        {/* <CreateRelatedServiceQuotationButton /> */}
      </FormTab>

      <FormTab label='resources.Provider.tabs.ratings'>
        <ReferenceManyField target='provider.id' reference='Rating' addLabel={false} fullWidth>
          <Datagrid>
            <ReferenceField source='user.id' reference='User'>
              <FunctionField render={record => `${record.forename} ${record.surname}`} />
            </ReferenceField>
            <NumberField source='costEffectiveness' />
            <NumberField source='reliability' />
            <NumberField source='quality' />
            <EditButton redirect={() => props.location.basePath} />
            <DeleteButton redirect={() => props.location.basePath} />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)

export const ProviderCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<ProviderCreateToolbar />}>
      <FormDataConsumer fullWidth>
        {formDataProps => (
          <NameInput source='name' validate={validateRequired} {...formDataProps} />
        )}
      </FormDataConsumer>
      <TextInput source='slug' validate={validateRequired} />
      <TextInput source='email' type='email' validate={validateEmail} />
      <NumberInput source='commission' validate={validateRequired} />
      <TextInput source='invoiceMail' type='email' />
      <TextInput source='termsAndConditionsUrl' type='url' />
      <ImageInput source='logo' accept='image/*'>
        <ImageField source='src' title='title' />
      </ImageInput>
    </SimpleForm>
  </Create>
)
