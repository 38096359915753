import React from 'react'
import LiveHelp from '@material-ui/icons/LiveHelp'
import RichTextInput from 'ra-input-rich-text'
import { Datagrid, List, TextField, TextInput, Edit, SimpleForm, Create } from 'react-admin'
import { Pagination } from '@material-ui/lab'

export const ServiceTagFaqIcon = LiveHelp

export const ServiceTagFaqList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <TextField source='question' />
      <TextField source='answer' />
    </Datagrid>
  </List>
)

export const ServiceTagFaqEdit = props => (
  <Edit {...props} title='FAQ'>
    <SimpleForm redirect='edit'>
      <TextInput source='question' />
      <RichTextInput source='answer' toolbar={[['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }]]} />
    </SimpleForm>
  </Edit>
)

export const ServiceTagFaqCreate = props => (
  <Create {...props}>
    <SimpleForm redirect='edit'>
      <TextInput source='question' />
      <RichTextInput source='answer' toolbar={[['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }]]} />
    </SimpleForm>
  </Create>
)
