import React from 'react'
import Icon from '@material-ui/icons/People'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import BlockIcon from '@material-ui/icons/Block'
import {
  Edit,
  List,
  Pagination,
  Datagrid,
  TabbedForm,
  FormTab,
  NumberField,
  TextField,
  EmailField,
  DateField,
  FunctionField,
  ReferenceField,
  SelectField,
  ReferenceManyField,
  TextInput,
  EditButton,
  DeleteButton,
  required,
  email
} from 'react-admin'

import { serviceQuotationRequestStateChoices } from '../utils'

export const UserIcon = Icon

const validateRequired = required()
const validateEmail = [required(), email()]

const UserTitle = ({ record }) => <span>{`${record.forename} ${record.surname}`}</span>

export const UserList = props => (
  <List
    perPage={1000}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 1000]} />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick='edit'>
      <FunctionField label='resources.User.fields.name' render={record => `${record.forename} ${record.surname}`} />
      <EmailField source='email' />
      <FunctionField label='resources.User.fields.verified' render={record => record.verificationToken ? <BlockIcon /> : <VerifiedUserIcon />} />
      <ReferenceField source='company.id' reference='Company'>
        <TextField source='name' />
      </ReferenceField>
      <DateField source='createdAt' locales='de-DE' showTime />
      <FunctionField label='resources.User.fields.trainingBookings' render={record => record.trainingBookings.length} />
      <FunctionField label='resources.User.fields.serviceQuotationRequests' render={record => record.serviceQuotationRequests.length} />
      <FunctionField label='resources.User.fields.ratings' render={record => record.ratings.length} />
    </Datagrid>
  </List>
)

export const UserEdit = props => (
  <Edit
    {...props}
    title={<UserTitle />}
  >
    <TabbedForm>
      <FormTab label='resources.User.tabs.general'>
        <TextInput source='forename' validate={validateRequired} />
        <TextInput source='surname' validate={validateRequired} />
        <TextInput source='email' type='email' validate={validateEmail} />
        <TextInput source='phone' type='tel' />
        <DateField source='createdAt' locales='de-DE' showTime />
        <FunctionField label='resources.User.fields.verified' render={record => record.verificationToken ? <BlockIcon /> : <VerifiedUserIcon />} />
        <ReferenceField source='billingAddress.id' reference='Address'>
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField source='company.id' reference='Company'>
          <TextField source='name' />
        </ReferenceField>
        <FunctionField label='resources.User.fields.verificationLink' render={record => record.verificationToken ? <span>{`https://opticert.de/verify?token=${record.verificationToken}`}</span> : null } />
      </FormTab>

      {/* <FormTab label='resources.User.tabs.billingAddress'>
        <ReferenceField source='billingAddress.id' reference='Address'>
          <TextField source='slug' />
          <TextInput source='name' />
          <TextInput source='street' />
          <TextInput source='streetNumber' />
          <TextInput source='additionalAddress' />
          <TextInput source='postalCode' />
          <TextInput source='city' />
          <TextField source='country' />
        </ReferenceField>
      </FormTab> */}

      <FormTab label='resources.User.tabs.trainingBookings'>
        <ReferenceManyField target='user.id' reference='TrainingBooking' addLabel={false} fullWidth>
          <Datagrid>
            <DateField source='createdAt' locales='de-DE' />
            <ReferenceField source='event.id' reference='TrainingEvent'>
              <ReferenceField source='training.id' reference='Training'>
                <ReferenceField source='serviceTag.id' reference='ServiceTag'>
                  <TextField source='name' />
                </ReferenceField>
              </ReferenceField>
            </ReferenceField>
            <ReferenceField source='event.id' reference='TrainingEvent'>
              <DateField source='startDate' locales='de-DE' />
            </ReferenceField>
            <NumberField source='participantCount' />
            <FunctionField label='resources.TrainingBooking.fields.totalPrice' render={record => `${record.pricePerParticipant * record.participantCount / 100} €`} />
            <EditButton redirect={() => props.location.basePath} />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>

      <FormTab label='resources.User.tabs.serviceQuotationRequests'>
        <ReferenceManyField target='user.id' reference='ServiceQuotationRequest' addLabel={false} fullWidth>
          <Datagrid>
            <DateField source='createdAt' locales='de-DE' showTime />
            <ReferenceField source='serviceTag.id' reference='ServiceTag'>
              <TextField source='name' />
            </ReferenceField>
            <SelectField source='state' choices={serviceQuotationRequestStateChoices} />
            <EditButton redirect={() => props.location.basePath} />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>

      <FormTab label='resources.User.tabs.ratings'>
        <ReferenceManyField target='user.id' reference='Rating' addLabel={false} fullWidth>
          <Datagrid>
            <ReferenceField source='provider.id' reference='Provider'>
              <TextField source='name' />
            </ReferenceField>
            <NumberField source='costEffectiveness' />
            <NumberField source='reliability' />
            <NumberField source='quality' />
            <EditButton redirect={() => props.location.basePath} />
            <DeleteButton redirect={() => props.location.basePath} />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
)
