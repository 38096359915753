import _slugify from '@sindresorhus/slugify'

export const topicChoices = [
  { id: 'OCCUPATIONAL_SAFETY', name: 'topics.occupationalSafety' },
  { id: 'OCCUPATIONAL_MEDICINE', name: 'topics.occupationalMedicine' },
  { id: 'FIRE_SAFETY', name: 'topics.fireSafety' },
  { id: 'DANGEROUS_GOODS', name: 'topics.dangerousGoods' },
  { id: 'DANGEROUS_SUBSTANCES', name: 'topics.dangerousSubstances' },
  { id: 'CERTIFICATION', name: 'topics.certification' },
  { id: 'PRIVACY', name: 'topics.privacy' },
  { id: 'MISCELLANEOUS', name: 'topics.miscellaneous' }
]

export const occurrenceProbabilityChoices = [
  { id: 1, name: 'occurrenceProbability.nearlyImpossible' },
  { id: 2, name: 'occurrenceProbability.unlikely' },
  { id: 3, name: 'occurrenceProbability.rarely' },
  { id: 4, name: 'occurrenceProbability.occasionally' },
  { id: 5, name: 'occurrenceProbability.frequently' }
]

export const indexChoices = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },
  { id: 4, name: '4' },
  { id: 5, name: '5' }
]

export const serviceQuotationRequestStateChoices = [
  { id: 'OPEN', name: 'serviceQuotationRequestStates.open' },
  { id: 'PROCESSING', name: 'serviceQuotationRequestStates.processing' },
  { id: 'CANCELLED', name: 'serviceQuotationRequestStates.cancelled' },
  { id: 'CLOSED', name: 'serviceQuotationRequestStates.closed' }
]

export const editorChoices = [
  { id: 'UNASSIGNED', name: 'words.unassigned' },
  { id: 'TOBI', name: 'Tobi' },
  { id: 'SABRINA', name: 'Sabrina' },
  { id: 'LEON', name: 'Leon' },
  { id: 'MARIO', name: 'Mario' },
  { id: 'TIM', name: 'Tim' }
]

export const serviceQuotationStateChoices = [
  { id: 'PENDING', name: 'serviceQuotationStates.pending' },
  { id: 'REJECTED', name: 'serviceQuotationStates.rejected' },
  { id: 'ACCEPTED', name: 'serviceQuotationStates.accepted' }
]

export const slugify = name => _slugify(name, {
  lowercase: true,
  decamelize: false,
  customReplacements: [
    ['&', 'und']
  ]
})

export const getShortQuotationRequestId = id => id.substr(-5, 5).toUpperCase()
