import React from 'react'
import { Image } from 'cloudinary-react'
import PropTypes from 'prop-types'

const TextField = ({ source, record = {}, ...props }) => <Image {...props} cloudName='opticert' publicId={record[source]} />

TextField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
}

// TextField.defaultProps = {
//   addLabel: true
// }

export default TextField
